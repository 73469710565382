<template>
	<a-form-model :model="signer" v-if="signer.id" class="signing-party-cmp">
		<div class="px-4 dF aC">

			<div>
				<a-form-model-item :rules="{ required: true }" prop="order" class="mb-0">
					<a-input-number :min="repReviewer ? 0 : 1" v-model.number="signer.order" class="signing-order-input"
						:disabled="repReviewer" />
				</a-form-model-item>
			</div>
			<div>
				<div class="signer-wrapper">
					<div class="dF aS">

						<div v-if="signer.supportingText" class="signing-party-supporting">
							{{ signer.supportingText }}
						</div>
						<a-icon @click="$emit('delete')" type="close-circle" class="cursor-pointer signing-party-supporting"
							style="font-size:15px;" v-if="signer.canBeRemoved" />
						<div class="dF fC">

							<template v-if="signer.vendor && choices.length">
								<a-form-model-item class="mb-0" prop="choice">
									<a-select v-model.lazy="signer.choice">
										<a-select-option v-for="(choice, choiceI) in choices" :key="choiceI + 'choiceI'"
											:value="choice.email">{{ choice.name }}</a-select-option>
										<a-select-option value="other">Other</a-select-option>
									</a-select>
								</a-form-model-item>
							</template>

							<template>

								<a-form-model-item prop="name" :rules="ruleReq('Cannot be empty')" class="mb-0"
									v-if="!signer.vendor || signer.choice === 'other'">
									<a-input :disabled="signer.disabled || signer.choice !== 'other'"
										placeholder="Recepient Name" v-model.lazy="signer.name" style="width:200px;" />
								</a-form-model-item>
								<template>

									<a-form-model-item :prop="`role`" class="mb-0">
										<a-input
											:disabled="signer.disabled || signer.choice !== 'other' || signer.roleDisabled"
											placeholder="Recepient Role" v-model.lazy="signer.role" style="width:200px;" />
									</a-form-model-item>

									<a-form-model-item :prop="`email`" :rules="ruleReq('Cannot be empty!')" class="mb-0">
										<a-input :disabled="signer.disabled || signer.choice !== 'other'"
											placeholder="Recepient Email" v-model.lazy="signer.email"
											style="width:200px;" />
									</a-form-model-item>

								</template>

							</template>

						</div>
						<div class="pl-3">
							<a-form-model-item class="mb-2" :prop="`action`">
								<a-select style="width:300px;" v-model.lazy="signer.action"
									:disabled="(signer.actionDisabled || signer.canSign) && !signer.forceActionDisabled">
									<a-select-option v-for="(type, typeI) in signerTypes" :key="typeI + 'signerType'"
										:value="type.value"><a-icon :type="type.ant"
											class="mr-3" />{{ type.label }}</a-select-option>
								</a-select>
							</a-form-model-item>
							<a-form-model-item label="Access Code" class="mb-0" :prop="`accessCode`"
								help="Leave blank to skip access code">
								<a-input v-model="signer.accessCode" />
							</a-form-model-item>
						</div>
					</div>
					<div v-if="signer.action === 'sign'">
						<a-form-model-item prop="docType" class="mb-0">
							<template slot="label">
								Signing Process
							</template>
							<a-radio-group v-model="signer.wantToSignInPerson">
								<a-radio-button :value=false>
									<a-icon type="file-protect" /> Online Through Docusign
								</a-radio-button>
								<a-radio-button :value="true">
									<a-icon type="highlight" /> In Person Signing
								</a-radio-button>
							</a-radio-group>
						</a-form-model-item>
					</div>
				</div>
				<div class="text-danger pl-4 mt-2" v-if="showError && hasError">Please review your EMAIL fields and try
					again</div>
			</div>
		</div>

	</a-form-model>
</template>

<script>
import { ruleReq, validateEmail } from 'bh-mod'
export default {
	props: {
		showError: {
			type: Boolean,
			default: false
		},
		repReviewer: {
			type: Boolean,
			default: false
		},
		choices: {
			type: Array,
			default: () => []
		},
		value: {
			type: Object,
			default: () => {
				return {
					solicitor: false,
					agent: false,
					signingParty: false,
					purchasers: false,
					purchaserSide: true,
					action: 'cc',
					actionDisabled: false,
					disabled: false,
					predefined: true,
					id: Date.now(),
					name: '',
					role: '',
					supportingText: '',
					email: '',
					note: '',
					choice: 'other'
				}
			}
		}
	},
	data() {
		return {
			signer: {},
		}
	},
	computed: {
		hasError() {
			return this.signer.canSign && !validateEmail(this.signer.email)
		},
		signerTypes() {
			let signerTypes = []

			if (this.repReviewer) {
				return [
					{
						label: 'Needs to Review',
						value: 'approve',
						ant: 'eye',
					}
				]
			}

			if (this.signer.canSign) {
				signerTypes = [
					{
						label: 'Needs to Sign',
						value: 'sign',
						ant: 'edit',
					}
				]
			}
			return [
				...signerTypes,
				{
					label: 'Receives a Copy',
					value: 'cc',
					ant: 'mail',
				},
				{
					label: 'Needs to Approve',
					value: 'approve',
					ant: 'eye',
				},
				{
					label: 'Don\'t Send a Copy',
					value: 'none',
					ant: 'stop',
				},
			]
		}
	},
	methods: { ruleReq },
	watch: {
		signer: {
			deep: true,
			handler(val, oldVal) {
				this.$emit('input', val)
				if (val.choice && val.vendor && val.choice === 'other' && oldVal.choice !== 'other') {
					this.signer.name = ''
					this.signer.email = ''
				} else if (val.vendor) {
					let found = this.choices.find(x => x.email === val.choice)
					if (found) {
						let { name, email } = found
						this.signer.name = name
						this.signer.email = email
					}

				}
			}
		}
	},
	created() {
		if (this.value) this.signer = { ...this.value, wantToSignInPerson: false }
	}
}
</script>

<style>
.signing-party-supporting {
	position: absolute;
	right: 0;
	top: 0;
	background: white;
	color: var(--gray);
	transform: translateX(-50%) translateY(-50%);
	padding: 0 10px;
}

.signer-wrapper {
	box-shadow: 0 2px 5px rgba(100, 100, 100, 0.1);
	position: relative;
	border: 1px solid var(--light-purple);
	padding: 20px;
	margin-left: 20px;
}

.signing-order-input {
	width: 50px;
}

.signing-party-cmp+.signing-party-cmp {
	margin-top: 20px;
}
</style>
